/**
 * VIEW CONTENT CLASS(ES)
 */

.eu-view-content {
  --_eu-view-content-Background: var(
    --eu-view-content-Background,
    var(--eu-global-Color-Base--03)
  );
  --_eu-view-content-BorderColor: var(--eu-view-content-BorderColor, #fff);
  --_eu-view-content-BorderRadius: var(
    --eu-view-content-BorderRadius,
    0px
  ); // needs unit so it can be used in calc()
  --_eu-view-content-BorderStyle: var(--eu-view-content-BorderStyle, solid);
  --_eu-view-content-BorderWidth: var(
    --eu-view-content-BorderWidth,
    0px
  ); // needs unit so it can be used in calc()
  --_eu-view-content-Display: var(--eu-view-content-Display, block);
  --_eu-view-content-Height: var(--eu-view-content-Height, auto);

  background: var(--_eu-view-content-Background);
  border-color: var(--_eu-view-content-BorderColor);
  border-radius: var(--_eu-view-content-BorderRadius);
  border-style: var(--_eu-view-content-BorderStyle);
  border-width: var(--_eu-view-content-BorderWidth);
  display: var(--_eu-view-content-Display);
  height: var(--_eu-view-content-Height);
}

.eu-view-content__primary {
  flex: auto;
  overflow: hidden;
}

.eu-view-content__secondary {
  --_eu-view-content__secondary-BorderRadius: 0;
  --_eu-view-content__secondary-Background: var(--eu-global-Color-Base--10);
  --_eu-view-content__secondary-Width: var(
    --eu-view-content__secondary-Width,
    auto
  );
  --_eu-view-content__secondary-MaxWidth: none;

  background-color: var(--_eu-view-content__secondary-Background);
  border-radius: var(--_eu-view-content__secondary-BorderRadius);
  max-width: var(--_eu-view-content__secondary-MaxWidth);
  overflow: hidden;
  width: var(--_eu-view-content__secondary-Width);
}

@include media('>=tabletPortrait') {
  .eu-view-content {
    --_eu-view-content-BorderRadius: var(
      --eu-view-content-BorderRadius,
      1.5rem
    );
    --_eu-view-content-BorderWidth: var(--eu-view-content-BorderWidth, 2px);
    --_eu-view-content-Display: var(--eu-view-content-Display, flex);
    --_eu-view-content-Height: var(--eu-view-content-Height, 100%);
  }

  .eu-view-content__secondary {
    --_eu-view-content__secondary-BorderRadius: calc(
      var(--_eu-view-content-BorderRadius) - var(--_eu-view-content-BorderWidth)
    );
    --_eu-view-content__secondary-Width: var(
      --eu-view-content__secondary-Width,
      34%
    );

    min-width: 22rem;
    padding: var(--_eu-view-content__primary-Margin);
    max-height: 100%;
  }
}
