@import './tools.mixins';

/**
 * BREADCRUMB CLASS(ES)
 */

.eu-breadcrumb {
  --_eu-item-Color: var(
    --eu-breadcrumb__item-Color,
    var(--eu-global-Color-Base--50)
  );

  --_eu-item__link-Color: var(
    --eu-breadcrumb__item__link-Color,
    var(--_eu-item-Color)
  );

  --_eu-item__icon-Color: var(
    --eu-breadcrumb__item__icon-Color,
    var(--eu-global-Color-Base)
  );

  --_eu-item__icon-Content: var(--eu-breadcrumb__item__icon-Content, '\e5df');

  --_eu-item-FontSize: var(
    --eu-breadcrumb__item-FontSize,
    var(--eu-global-FontSize--s)
  );

  display: none;
  align-content: center;
}

.eu-breadcrumb__item {
  color: var(--_eu-item-Color);
  font-size: var(--_eu-item-FontSize);

  &:not(:last-child) {
    padding-right: 0.25rem;
  }
  &:last-child {
    font-weight: bold;
  }

  &:not(:first-child) {
    &::before {
      @include material-icons();
      content: var(--_eu-item__icon-Content);
      color: var(--_eu-item__icon-Color);
      vertical-align: bottom;
      line-height: calc(var(--_eu-item-FontSize) * 1.5);
    }
  }

  a {
    color: var(--_eu-item__link-Color);

    &:hover {
      border-bottom: 1px dotted;
    }
  }
}

@include media('>=tabletPortrait') {
  .eu-breadcrumb {
    display: flex;
  }
}
