/**
 * CONTACT GROUP CLASS(ES)
 */

.eu-contact-card-group {
  --_eu-group-item-MinWidth: 280px;
  --_eu-group-item-MaxWidth: 1fr;
  --_eu-group-GridGap: 1rem;
  --_eu-group-GridTemplateColumns: repeat(
    auto-fill,
    minmax(var(--_eu-group-item-MinWidth), var(--_eu-group-item-MaxWidth))
  );

  display: grid;
  grid-template-columns: var(--_eu-group-GridTemplateColumns);
  gap: var(--_eu-group-GridGap);
}
