html,
body {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  @include fluid-type(
    var(--eu-global-fluid-Fontsize--min),
    var(--eu-global-fluid-Fontsize--max),
    #{$global-fluid-screen--min},
    #{$global-fluid-screen--max}
  ); // adding fluid font-size
}

body {
  font-family: var(--eu-global-FontFamily);
  font-weight: var(--eu-global-FontWeight);
  line-height: 1.5;
  color: var(--eu-global-TextColor);
  margin: 0;
  padding: 0;
  overflow: hidden;
}

a {
  color: var(--eu-global-LinkColor);
  text-decoration: none;
}

// TODO sort in

og-combobox {
  --og-combobox__indicator-Background: #ac3e74;
}

og-combobox-options {
  // --og-combobox__flyout__item-Background--hover: hsl(331, 67%, 46%, 0.12);
  // --og-combobox__flyout__item-Background--active: hsl(331, 67%, 46%, 0.24);
  --og-combobox__flyout__item-Background--hover: var(
    --OG-COLOR-PRIMARY--100--10,
    rgba(29, 162, 211, 0.1)
  );
  --og-combobox__flyout__item-Background--active: var(
    --OG-COLOR-PRIMARY--100--50,
    rgba(29, 162, 211, 0.5)
  );
  // --og-combobox__flyout__item-Background--active: #1da2d3;

  box-shadow: 0 -1px 0 0 white, 0 0px 0 1px #d9dfdf, 0 2px 5px #d9dfdf;
  border-radius: 0 0 8px 8px;
  z-index: 10000;
}
og-progress {
  --og-progress-Background: hsl(331, 67%, 46%, 0.12);
  --og-progress-Color: #ac3e74;
}
