/**
 * FONTS
 * ----------------------------------------------------------------------------
 * This file contains custom font-family definitions.
 */

@font-face {
  font-family: 'Titillium Web';
  src: url('TitilliumWeb-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

$global-FontFamily: 'Titillium Web', sans-serif;
$global-FontWeight--light: 300;
$global-FontWeight--regular: 400;
$global-FontWeight--semi-bold: 600;
$global-FontWeight--bold: 700;
$global-FontWeight: $global-FontWeight--regular;

/**
 * Font Sizes
 * We use rem calculated with a base size of 16px @ 960px as default view-width.
 *              480 |   960 |
 *  0.75rem |  10px |  12px |
 * 0.875rem |  12px |  14px |
 *     1rem |  14px |  16px |
 * 1.125rem |  16px |  18px |
 *  1.25rem |  18px |  20px |
 * 1.375rem |  20px |  22px |
 *  1.75rem |  24px |  28px |
 *  2.25rem |  28px |  36px |
 */

$global-FontSize--xs: 0.75rem;
$global-FontSize--s: 0.875rem;
$global-FontSize--m: 1rem;
$global-FontSize--l: 1.125rem;
$global-FontSize--xl: 1.25rem;
$global-FontSize--2xl: 1.375rem;
$global-FontSize--3xl: 1.75rem;
$global-FontSize--4xl: 2.25rem;

$global-FontSize: $global-FontSize--m;

// For fluid fonts we need a start and end value in px. Write without units.
// At the moment we don't scale...
$global-fluid-FontSize--min: 16;
$global-fluid-FontSize--max: 16;

// This also applies to the screen-widths between which scaling occurs.
$global-fluid-screen--min: 480;
$global-fluid-screen--max: 960;

// =============================================================================
/**
 * Font Sizes
 * We use rem calculated with a base size of 16px @ 960px as default view-width.
 *              480 |   960 |
 *  0.75rem |  10px |  12px |
 * 0.875rem |  12px |  14px |
 *     1rem |  14px |  16px |
 * 1.125rem |  16px |  18px |
 *  1.25rem |  18px |  20px |
 * 1.375rem |  20px |  22px |
 *  1.75rem |  24px |  28px |
 *  2.25rem |  28px |  36px |
 */

$eu-Fontsize--xs: 0.75rem;
$eu-Fontsize--s: 0.875rem;
$eu-Fontsize: 1rem;
$eu-Fontsize--l: 1.125rem;
$eu-Fontsize--xl: 1.25rem;
$eu-Fontsize--2xl: 1.375rem;
$eu-Fontsize--3xl: 1.75rem;
$eu-Fontsize--4xl: 2.25rem;
