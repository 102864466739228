@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(materialicons.woff2) format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
  transition: 0.3s;
}
@media only screen and (max-width: 500px) {
  .mat-mdc-card-outlined{
    .mat-mdc-button .mdc-button__label, .mat-mdc-unelevated-button .mdc-button__label, .mat-mdc-raised-button .mdc-button__label, .mat-mdc-outlined-button .mdc-button__label{
      font-size: 11px;
    }
  }
  .eu-flyout.d-flex.flex-column.position-fix{
    max-width: 300px!important;
    left: -74px;
    &::before{
      left: 75%;
    }
    eu-shopcart-flyout{
      width: 100%!important;
    }
  }
}
// .eu-block.eu-block--double{
//   div.og-expander__content{
//     display: block!important;
//     min-height: auto;
//     transition: 0.4s;

//   }
//   div.og-expander__content[data-expanded]{
//     display: block!important;
//     min-height: 0;
//     overflow: hidden;
//   }
// }
.og-sidebar .og-list{
  text-indent: 10px;
}
.eu-product-row__action.eu-button.eu-product-row__add-product, .eu-product-row--medium[_ngcontent-ng-c3202252130] .eu-product-row__add-product[_ngcontent-ng-c3202252130]{
  display: flex;
  align-items: center;
}
a[_ngcontent-ng-c232240170]{
  border-bottom: 1px dotted transparent;
}
.mat-pseudo-checkbox.mat-mdc-option-pseudo-checkbox.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal.ng-star-inserted{
  margin-bottom: 8px;
}
.eu-product-row__action.eu-button.eu-button--second.mdc-button.mat-mdc-button.mat-unthemed.mat-mdc-button-base{
  max-width: none;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 0;
  margin-bottom: 0;
}
.eu-app .mat-mdc-button.mat-mdc-button-base, .eu-app .mat-mdc-raised-button.mat-mdc-button-base, .eu-app .mat-mdc-unelevated-button.mat-mdc-button-base, .eu-app .mat-mdc-outlined-button.mat-mdc-button-base{
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 0;
  margin-bottom: 0;
}
.eu-product-row__add-product[_ngcontent-ng-c3202252130]{
  max-width: none;
}
@media screen and (max-width: 500px) {
  .eu-product-row__action.eu-button.eu-button--second.mdc-button.mat-mdc-button.mat-unthemed.mat-mdc-button-base{

    margin-top: 5px;
    margin-bottom: 5px;
  }
  .eu-app .mat-mdc-button.mat-mdc-button-base, .eu-app .mat-mdc-raised-button.mat-mdc-button-base, .eu-app .mat-mdc-unelevated-button.mat-mdc-button-base, .eu-app .mat-mdc-outlined-button.mat-mdc-button-base{

    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.mat-badge.filter-label.mat-badge-primary.mat-badge-above.mat-badge-after.mat-badge-medium.mat-badge-disabled{
  max-width: 300px!important;
}

.eu-newsbanner{
  max-width: 700px;
  position: absolute;
  left: calc(50% - 350px);
  top: 20px;
  opacity: 0.85;
  z-index: 9;
      li{
        display: none;
        &:first-of-type{
          display: flex;
        }
      }
  .eu-teaser-item__img{
    margin-left: 0!important;
    margin-top: 0!important;
    text-align: center;
      img{
          max-width: 60%;
      }
  }
  .eu-teaser-item__content{
    padding-left: 0!important;
  }
}
@media screen and (max-width: 500px) {
  .eu-newsbanner{
    left: 0;
    top: 0;
    .eu-teaser-item__img{
      img{
          max-width: 27%;
      }
    }
  }
}
