/**
 * TABBAR CLASS(ES)
 */

.eu-tabbar {
  --_eu-item-Color: inherit;
  --_eu-item-Color--hover: var(--eu-global-Color-Leading);
  --_eu-item-FontSize: var(--eu-global-FontSize--2xl);
  --_eu-item-FontWeight: var(--eu-global-FontWeight--bold);
  --_eu-item-Padding: 0.25rem 0;
  --_eu-item__indicator-Background: transparent;
  --_eu-item__indicator-Background--active: var(--eu-global-Color-Leading);
  --_eu-item__indicator-BorderRadius: 1rem;

  display: flex;
  overflow-x: auto; // TODO how do we handle overflow?
}

.eu-tabbar__item {
  color: var(--_eu-item-Color);
  font-size: var(--_eu-item-FontSize);
  font-weight: var(--_eu-item-FontWeight);
  padding: var(--_eu-item-Padding);
  position: relative;
  cursor: pointer;
  transition: color 150ms ease-in-out;
  white-space: nowrap;

  & + & {
    margin-left: 1.5rem;
  }

  //Indicator
  &::after {
    background: var(--_eu-item__indicator-Background);
    border-radius: var(--_eu-item__indicator-BorderRadius);
    content: '';
    height: 0.25rem;
    position: absolute;
    top: auto;
    right: 0;
    bottom: 0;
    left: 0;
    transition: background 150ms ease-in-out;
  }

  &:hover {
    --_eu-item-Color: var(--_eu-item-Color--hover);
  }
}

.eu-tabbar__item--active {
  --_eu-item__indicator-Background: var(
    --_eu-item__indicator-Background--active
  );
}
