@import './settings.sizes';

// REPSONSIVE SPACING UTILITY
// =============================================================================
//
// Usage:
// <div class="pl--xs s:pl--m xl:pl--xxl">...</div>
// Applies
// - 0.25rem on '1px' and upwards
// - 1.00rem on 'mobileLandscape' and upwards
// - 3.00rem on 'desktop' and upwards
// =============================================================================

$space--xs: 0.25rem;
$space--s: 0.5rem;
$space--m: 1rem;
$space--l: 1.5rem;
$space--xl: 2rem;
$space--xxl: 3rem;

// PADDINGS
// -----------------------------------------------------------------------------

// All @ all
.pa--0 {
  padding: 0;
}
.pa--xs {
  padding: $space--xs;
}
.pa--s {
  padding: $space--s;
}
.pa--m {
  padding: $space--m;
}
.pa--l {
  padding: $space--l;
}
.pa--xl {
  padding: $space--xl;
}
.pa--xxl {
  padding: $space--xxl;
}

// All @ m
@include media('>=tabletPortrait') {
  .m\:pa--0 {
    padding: 0;
  }
  .m\:pa--xs {
    padding: $space--xs;
  }
  .m\:pa--s {
    padding: $space--s;
  }
  .m\:pa--m {
    padding: $space--m;
  }
  .m\:pa--l {
    padding: $space--l;
  }
  .m\:pa--xl {
    padding: $space--xl;
  }
  .m\:pa--xxl {
    padding: $space--xxl;
  }
}

// All @ xl
@include media('>=desktop') {
  .xl\:pa--0 {
    padding: 0;
  }
  .xl\:pa--xs {
    padding: $space--xs;
  }
  .xl\:pa--s {
    padding: $space--s;
  }
  .xl\:pa--m {
    padding: $space--m;
  }
  .xl\:pa--l {
    padding: $space--l;
  }
  .xl\:pa--xl {
    padding: $space--xl;
  }
  .xl\:pa--xxl {
    padding: $space--xxl;
  }
}

// All @ xxl
@include media('>=desktopLarge') {
  .xxl\:pa--0 {
    padding: 0;
  }
  .xxl\:pa--xs {
    padding: $space--xs;
  }
  .xxl\:pa--s {
    padding: $space--s;
  }
  .xxl\:pa--m {
    padding: $space--m;
  }
  .xxl\:pa--l {
    padding: $space--l;
  }
  .xxl\:pa--xl {
    padding: $space--xl;
  }
  .xxl\:pa--xxl {
    padding: $space--xxl;
  }
}

// Top @ all
.pt--0 {
  padding-top: 0;
}
.pt--xs {
  padding-top: $space--xs;
}
.pt--s {
  padding-top: $space--s;
}
.pt--m {
  padding-top: $space--m;
}
.pt--l {
  padding-top: $space--l;
}
.pt--xl {
  padding-top: $space--xl;
}
.pt--xxl {
  padding-top: $space--xxl;
}

// Top @ m
@include media('>=tabletPortrait') {
  .m\:pt--0 {
    padding-top: 0;
  }
  .m\:pt--xs {
    padding-top: $space--xs;
  }
  .m\:pt--s {
    padding-top: $space--s;
  }
  .m\:pt--m {
    padding-top: $space--m;
  }
  .m\:pt--l {
    padding-top: $space--l;
  }
  .m\:pt--xl {
    padding-top: $space--xl;
  }
  .m\:pt--xxl {
    padding-top: $space--xxl;
  }
}

// Top @ xl
@include media('>=desktop') {
  .xl\:pt--0 {
    padding-top: 0;
  }
  .xl\:pt--xs {
    padding-top: $space--xs;
  }
  .xl\:pt--s {
    padding-top: $space--s;
  }
  .xl\:pt--m {
    padding-top: $space--m;
  }
  .xl\:pt--l {
    padding-top: $space--l;
  }
  .xl\:pt--xl {
    padding-top: $space--xl;
  }
  .xl\:pt--xxl {
    padding-top: $space--xxl;
  }
}

// Top @ xxl
@include media('>=desktopLarge') {
  .xxl\:pt--0 {
    padding-top: 0;
  }
  .xxl\:pt--xs {
    padding-top: $space--xs;
  }
  .xxl\:pt--s {
    padding-top: $space--s;
  }
  .xxl\:pt--m {
    padding-top: $space--m;
  }
  .xxl\:pt--l {
    padding-top: $space--l;
  }
  .xxl\:pt--xl {
    padding-top: $space--xl;
  }
  .xxl\:pt--xxl {
    padding-top: $space--xxl;
  }
}

// Right @ all
.pr--0 {
  padding-right: 0;
}
.pr--xs {
  padding-right: $space--xs;
}
.pr--s {
  padding-right: $space--s;
}
.pr--m {
  padding-right: $space--m;
}
.pr--l {
  padding-right: $space--l;
}
.pr--xl {
  padding-right: $space--xl;
}
.pr--xxl {
  padding-right: $space--xxl;
}

// Right @ m
@include media('>=tabletPortrait') {
  .m\:pr--0 {
    padding-right: 0;
  }
  .m\:pr--xs {
    padding-right: $space--xs;
  }
  .m\:pr--s {
    padding-right: $space--s;
  }
  .m\:pr--m {
    padding-right: $space--m;
  }
  .m\:pr--l {
    padding-right: $space--l;
  }
  .m\:pr--xl {
    padding-right: $space--xl;
  }
  .m\:pr--xxl {
    padding-right: $space--xxl;
  }
}

// Right @ xl
@include media('>=desktop') {
  .xl\:pr--0 {
    padding-right: 0;
  }
  .xl\:pr--xs {
    padding-right: $space--xs;
  }
  .xl\:pr--s {
    padding-right: $space--s;
  }
  .xl\:pr--m {
    padding-right: $space--m;
  }
  .xl\:pr--l {
    padding-right: $space--l;
  }
  .xl\:pr--xl {
    padding-right: $space--xl;
  }
  .xl\:pr--xxl {
    padding-right: $space--xxl;
  }
}

// Right @ xxl
@include media('>=desktopLarge') {
  .xxl\:pr--0 {
    padding-right: 0;
  }
  .xxl\:pr--xs {
    padding-right: $space--xs;
  }
  .xxl\:pr--s {
    padding-right: $space--s;
  }
  .xxl\:pr--m {
    padding-right: $space--m;
  }
  .xxl\:pr--l {
    padding-right: $space--l;
  }
  .xxl\:pr--xl {
    padding-right: $space--xl;
  }
  .xxl\:pr--xxl {
    padding-right: $space--xxl;
  }
}

// Bottom @ all
.pb--0 {
  padding-bottom: 0;
}
.pb--xs {
  padding-bottom: $space--xs;
}
.pb--s {
  padding-bottom: $space--s;
}
.pb--m {
  padding-bottom: $space--m;
}
.pb--l {
  padding-bottom: $space--l;
}
.pb--xl {
  padding-bottom: $space--xl;
}
.pb--xxl {
  padding-bottom: $space--xxl;
}

// Bottom @ m
@include media('>=tabletPortrait') {
  .m\:pb--0 {
    padding-bottom: 0;
  }
  .m\:pb--xs {
    padding-bottom: $space--xs;
  }
  .m\:pb--s {
    padding-bottom: $space--s;
  }
  .m\:pb--m {
    padding-bottom: $space--m;
  }
  .m\:pb--l {
    padding-bottom: $space--l;
  }
  .m\:pb--xl {
    padding-bottom: $space--xl;
  }
  .m\:pb--xxl {
    padding-bottom: $space--xxl;
  }
}

// Bottom @ xl
@include media('>=desktop') {
  .xl\:pb--0 {
    padding-bottom: 0;
  }
  .xl\:pb--xs {
    padding-bottom: $space--xs;
  }
  .xl\:pb--s {
    padding-bottom: $space--s;
  }
  .xl\:pb--m {
    padding-bottom: $space--m;
  }
  .xl\:pb--l {
    padding-bottom: $space--l;
  }
  .xl\:pb--xl {
    padding-bottom: $space--xl;
  }
  .xl\:pb--xxl {
    padding-bottom: $space--xxl;
  }
}

// Bottom @ xxl
@include media('>=desktopLarge') {
  .xxl\:pb--0 {
    padding-bottom: 0;
  }
  .xxl\:pb--xs {
    padding-bottom: $space--xs;
  }
  .xxl\:pb--s {
    padding-bottom: $space--s;
  }
  .xxl\:pb--m {
    padding-bottom: $space--m;
  }
  .xxl\:pb--l {
    padding-bottom: $space--l;
  }
  .xxl\:pb--xl {
    padding-bottom: $space--xl;
  }
  .xxl\:pb--xxl {
    padding-bottom: $space--xxl;
  }
}

// Left @ all
.pl--0 {
  padding-left: 0;
}
.pl--xs {
  padding-left: $space--xs;
}
.pl--s {
  padding-left: $space--s;
}
.pl--m {
  padding-left: $space--m;
}
.pl--l {
  padding-left: $space--l;
}
.pl--xl {
  padding-left: $space--xl;
}
.pl--xxl {
  padding-left: $space--xxl;
}

// Left @ m
@include media('>=tabletPortrait') {
  .m\:pl--0 {
    padding-left: 0;
  }
  .m\:pl--xs {
    padding-left: $space--xs;
  }
  .m\:pl--s {
    padding-left: $space--s;
  }
  .m\:pl--m {
    padding-left: $space--m;
  }
  .m\:pl--l {
    padding-left: $space--l;
  }
  .m\:pl--xl {
    padding-left: $space--xl;
  }
  .m\:pl--xxl {
    padding-left: $space--xxl;
  }
}

// Left @ xl
@include media('>=desktop') {
  .xl\:pl--0 {
    padding-left: 0;
  }
  .xl\:pl--xs {
    padding-left: $space--xs;
  }
  .xl\:pl--s {
    padding-left: $space--s;
  }
  .xl\:pl--m {
    padding-left: $space--m;
  }
  .xl\:pl--l {
    padding-left: $space--l;
  }
  .xl\:pl--xl {
    padding-left: $space--xl;
  }
  .xl\:pl--xxl {
    padding-left: $space--xxl;
  }
}

// Left @ xxl
@include media('>=desktopLarge') {
  .xxl\:pl--0 {
    padding-left: 0;
  }
  .xxl\:pl--xs {
    padding-left: $space--xs;
  }
  .xxl\:pl--s {
    padding-left: $space--s;
  }
  .xxl\:pl--m {
    padding-left: $space--m;
  }
  .xxl\:pl--l {
    padding-left: $space--l;
  }
  .xxl\:pl--xl {
    padding-left: $space--xl;
  }
  .xxl\:pl--xxl {
    padding-left: $space--xxl;
  }
}

// MARGINS
// -----------------------------------------------------------------------------

// All @ all
.ma--0 {
  margin: 0;
}
.ma--xs {
  margin: $space--xs;
}
.ma--s {
  margin: $space--s;
}
.ma--m {
  margin: $space--m;
}
.ma--l {
  margin: $space--l;
}
.ma--xl {
  margin: $space--xl;
}
.ma--xxl {
  margin: $space--xxl;
}

// All @ m
@include media('>=tabletPortrait') {
  .m\:ma--0 {
    margin: 0;
  }
  .m\:ma--xs {
    margin: $space--xs;
  }
  .m\:ma--s {
    margin: $space--s;
  }
  .m\:ma--m {
    margin: $space--m;
  }
  .m\:ma--l {
    margin: $space--l;
  }
  .m\:ma--xl {
    margin: $space--xl;
  }
  .m\:ma--xxl {
    margin: $space--xxl;
  }
}

// All @ xl
@include media('>=desktop') {
  .xl\:ma--0 {
    margin: 0;
  }
  .xl\:ma--xs {
    margin: $space--xs;
  }
  .xl\:ma--s {
    margin: $space--s;
  }
  .xl\:ma--m {
    margin: $space--m;
  }
  .xl\:ma--l {
    margin: $space--l;
  }
  .xl\:ma--xl {
    margin: $space--xl;
  }
  .xl\:ma--xxl {
    margin: $space--xxl;
  }
}

// All @ xxl
@include media('>=desktopLarge') {
  .xxl\:ma--0 {
    margin: 0;
  }
  .xxl\:ma--xs {
    margin: $space--xs;
  }
  .xxl\:ma--s {
    margin: $space--s;
  }
  .xxl\:ma--m {
    margin: $space--m;
  }
  .xxl\:ma--l {
    margin: $space--l;
  }
  .xxl\:ma--xl {
    margin: $space--xl;
  }
  .xxl\:ma--xxl {
    margin: $space--xxl;
  }
}

// Top @ all
.mt--0 {
  margin-top: 0;
}
.mt--xs {
  margin-top: $space--xs;
}
.mt--s {
  margin-top: $space--s;
}
.mt--m {
  margin-top: $space--m;
}
.mt--l {
  margin-top: $space--l;
}
.mt--xl {
  margin-top: $space--xl;
}
.mt--xxl {
  margin-top: $space--xxl;
}

// Top @ m
@include media('>=tabletPortrait') {
  .m\:mt--0 {
    margin-top: 0;
  }
  .m\:mt--xs {
    margin-top: $space--xs;
  }
  .m\:mt--s {
    margin-top: $space--s;
  }
  .m\:mt--m {
    margin-top: $space--m;
  }
  .m\:mt--l {
    margin-top: $space--l;
  }
  .m\:mt--xl {
    margin-top: $space--xl;
  }
  .m\:mt--xxl {
    margin-top: $space--xxl;
  }
}

// Top @ xl
@include media('>=desktop') {
  .xl\:mt--0 {
    margin-top: 0;
  }
  .xl\:mt--xs {
    margin-top: $space--xs;
  }
  .xl\:mt--s {
    margin-top: $space--s;
  }
  .xl\:mt--m {
    margin-top: $space--m;
  }
  .xl\:mt--l {
    margin-top: $space--l;
  }
  .xl\:mt--xl {
    margin-top: $space--xl;
  }
  .xl\:mt--xxl {
    margin-top: $space--xxl;
  }
}

// Top @ xxl
@include media('>=desktopLarge') {
  .xxl\:mt--0 {
    margin-top: 0;
  }
  .xxl\:mt--xs {
    margin-top: $space--xs;
  }
  .xxl\:mt--s {
    margin-top: $space--s;
  }
  .xxl\:mt--m {
    margin-top: $space--m;
  }
  .xxl\:mt--l {
    margin-top: $space--l;
  }
  .xxl\:mt--xl {
    margin-top: $space--xl;
  }
  .xxl\:mt--xxl {
    margin-top: $space--xxl;
  }
}

// Right @ all
.mr--0 {
  margin-right: 0;
}
.mr--xs {
  margin-right: $space--xs;
}
.mr--s {
  margin-right: $space--s;
}
.mr--m {
  margin-right: $space--m;
}
.mr--l {
  margin-right: $space--l;
}
.mr--xl {
  margin-right: $space--xl;
}
.mr--xxl {
  margin-right: $space--xxl;
}

// Right @ m
@include media('>=tabletPortrait') {
  .m\:mr--0 {
    margin-right: 0;
  }
  .m\:mr--xs {
    margin-right: $space--xs;
  }
  .m\:mr--s {
    margin-right: $space--s;
  }
  .m\:mr--m {
    margin-right: $space--m;
  }
  .m\:mr--l {
    margin-right: $space--l;
  }
  .m\:mr--xl {
    margin-right: $space--xl;
  }
  .m\:mr--xxl {
    margin-right: $space--xxl;
  }
}

// Right @ xl
@include media('>=desktop') {
  .xl\:mr--0 {
    margin-right: 0;
  }
  .xl\:mr--xs {
    margin-right: $space--xs;
  }
  .xl\:mr--s {
    margin-right: $space--s;
  }
  .xl\:mr--m {
    margin-right: $space--m;
  }
  .xl\:mr--l {
    margin-right: $space--l;
  }
  .xl\:mr--xl {
    margin-right: $space--xl;
  }
  .xl\:mr--xxl {
    margin-right: $space--xxl;
  }
}

// Right @ xxl
@include media('>=desktopLarge') {
  .xxl\:mr--0 {
    margin-right: 0;
  }
  .xxl\:mr--xs {
    margin-right: $space--xs;
  }
  .xxl\:mr--s {
    margin-right: $space--s;
  }
  .xxl\:mr--m {
    margin-right: $space--m;
  }
  .xxl\:mr--l {
    margin-right: $space--l;
  }
  .xxl\:mr--xl {
    margin-right: $space--xl;
  }
  .xxl\:mr--xxl {
    margin-right: $space--xxl;
  }
}

// Bottom @ all
.mb--0 {
  margin-bottom: 0;
}
.mb--xs {
  margin-bottom: $space--xs;
}
.mb--s {
  margin-bottom: $space--s;
}
.mb--m {
  margin-bottom: $space--m;
}
.mb--l {
  margin-bottom: $space--l;
}
.mb--xl {
  margin-bottom: $space--xl;
}
.mb--xxl {
  margin-bottom: $space--xxl;
}

// Bottom @ m
@include media('>=tabletPortrait') {
  .m\:mb--0 {
    margin-bottom: 0;
  }
  .m\:mb--xs {
    margin-bottom: $space--xs;
  }
  .m\:mb--s {
    margin-bottom: $space--s;
  }
  .m\:mb--m {
    margin-bottom: $space--m;
  }
  .m\:mb--l {
    margin-bottom: $space--l;
  }
  .m\:mb--xl {
    margin-bottom: $space--xl;
  }
  .m\:mb--xxl {
    margin-bottom: $space--xxl;
  }
}

// Bottom @ xl
@include media('>=desktop') {
  .xl\:mb--0 {
    margin-bottom: 0;
  }
  .xl\:mb--xs {
    margin-bottom: $space--xs;
  }
  .xl\:mb--s {
    margin-bottom: $space--s;
  }
  .xl\:mb--m {
    margin-bottom: $space--m;
  }
  .xl\:mb--l {
    margin-bottom: $space--l;
  }
  .xl\:mb--xl {
    margin-bottom: $space--xl;
  }
  .xl\:mb--xxl {
    margin-bottom: $space--xxl;
  }
}

// Bottom @ xxl
@include media('>=desktopLarge') {
  .xxl\:mb--0 {
    margin-bottom: 0;
  }
  .xxl\:mb--xs {
    margin-bottom: $space--xs;
  }
  .xxl\:mb--s {
    margin-bottom: $space--s;
  }
  .xxl\:mb--m {
    margin-bottom: $space--m;
  }
  .xxl\:mb--l {
    margin-bottom: $space--l;
  }
  .xxl\:mb--xl {
    margin-bottom: $space--xl;
  }
  .xxl\:mb--xxl {
    margin-bottom: $space--xxl;
  }
}

// Left @ all
.ml--0 {
  margin-left: 0;
}
.ml--xs {
  margin-left: $space--xs;
}
.ml--s {
  margin-left: $space--s;
}
.ml--m {
  margin-left: $space--m;
}
.ml--l {
  margin-left: $space--l;
}
.ml--xl {
  margin-left: $space--xl;
}
.ml--xxl {
  margin-left: $space--xxl;
}

// Left @ m
@include media('>=tabletPortrait') {
  .m\:ml--0 {
    margin-left: 0;
  }
  .m\:ml--xs {
    margin-left: $space--xs;
  }
  .m\:ml--s {
    margin-left: $space--s;
  }
  .m\:ml--m {
    margin-left: $space--m;
  }
  .m\:ml--l {
    margin-left: $space--l;
  }
  .m\:ml--xl {
    margin-left: $space--xl;
  }
  .m\:ml--xxl {
    margin-left: $space--xxl;
  }
}

// Left @ xl
@include media('>=desktop') {
  .xl\:ml--0 {
    margin-left: 0;
  }
  .xl\:ml--xs {
    margin-left: $space--xs;
  }
  .xl\:ml--s {
    margin-left: $space--s;
  }
  .xl\:ml--m {
    margin-left: $space--m;
  }
  .xl\:ml--l {
    margin-left: $space--l;
  }
  .xl\:ml--xl {
    margin-left: $space--xl;
  }
  .xl\:ml--xxl {
    margin-left: $space--xxl;
  }
}

// Left @ xxl
@include media('>=desktopLarge') {
  .xxl\:ml--0 {
    margin-left: 0;
  }
  .xxl\:ml--xs {
    margin-left: $space--xs;
  }
  .xxl\:ml--s {
    margin-left: $space--s;
  }
  .xxl\:ml--m {
    margin-left: $space--m;
  }
  .xxl\:ml--l {
    margin-left: $space--l;
  }
  .xxl\:ml--xl {
    margin-left: $space--xl;
  }
  .xxl\:ml--xxl {
    margin-left: $space--xxl;
  }
}
