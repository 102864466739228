@import 'tools.functions';

/**
 * Override default include-media breakpoints
 * https://ricostacruz.com/til/css-media-query-breakpoints
 */
$breakpoint-s: 480px; // Mobile-landscape (and larger)
$breakpoint-m: 768px; // Tablet-portrait (and larger)
$breakpoint-l: 992px; // Tablet-landscape (and larger)
$breakpoint-xl: 1200px; // Laptop/desktop (and larger)
$breakpoint-xxl: 1400px; // Large desktop screens

$breakpoint-s--em: em($breakpoint-s);
$breakpoint-m--em: em($breakpoint-m);
$breakpoint-l--em: em($breakpoint-l);
$breakpoint-xl--em: em($breakpoint-xl);
$breakpoint-xxl--em: em($breakpoint-xxl);

$breakpoints: (
  'mobileLandscape': $breakpoint-s,
  'tabletPortrait': $breakpoint-m,
  'tabletLandscape': $breakpoint-l,
  'desktop': $breakpoint-xl,
  'desktopLarge': $breakpoint-xxl,
);

$spacing: 8px;
$spacing-s: $spacing * 0.5;
$spacing-m: $spacing * 2;
$spacing-l: $spacing * 4;
$spacing-xl: $spacing * 8;

$spacing--em: em($spacing);
$spacing-s--em: em($spacing-s);
$spacing-m--em: em($spacing-m);
$spacing-l--em: em($spacing-l);
$spacing-xl--em: em($spacing-xl);

$container-width--max: 1440px;

$product-tile-MinWidth: 21rem; // 336px @ 16px
$product-tile-MaxWidth: 1fr;
$product-list-GridGap: 1rem;
$product-list-rows-Gap: 0.25rem;

// =============================================================================

$eu-space--xxs: 0.25rem;
$eu-space--xs: 0.5rem;
$eu-space--s: 0.75rem;
$eu-space: 1rem;
$eu-space--l: 1.25rem;
$eu-space--xl: 1.5rem;
$eu-space--xxl: 2rem;
