// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// http://mcg.mbitson.com/#!?mcgpalette0=%23ac3e74
$eu-lead: (
  50: #f5e8ee,
  100: #e6c5d5,
  200: #d69fba,
  300: #c5789e,
  400: #b85b89,
  500: #ac3e74,
  600: #a5386c,
  700: #9b3061,
  800: #4b2838,
  900: #821b44,
  A100: #ffbcd4,
  A200: #ff89b4,
  A400: #ff5694,
  A700: #ff3c84,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #ffffff,
  ),
);

// http://mcg.mbitson.com/#!?euleadpalette=%2307313c
$eu-base: (
  50: #e1e6e8,
  100: #b5c1c5,
  200: #83989e,
  300: #516f77,
  400: #2c5059,
  500: #07313c,
  600: #062c36,
  700: #05252e,
  800: #041f27,
  900: #02131a,
  A100: #58c3ff,
  A200: #25b1ff,
  A400: #009bf1,
  A700: #008bd8,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$webshop-primary: mat.m2-define-palette($eu-lead);
$webshop-accent: mat.m2-define-palette($eu-base, A200, A100, A400);

// The warn palette is optional (defaults to red).
$webshop-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$webshop-theme: mat.m2-define-light-theme((
  color: (
    primary: $webshop-primary,
    accent: $webshop-accent,
    warn: $webshop-warn,
  ),
  typography: mat.m2-define-typography-config(),
  density: 0,
));

// Include the theme styles
@include mat.all-component-themes($webshop-theme);

// Eurofleurs Typography
$eu-typography: mat.m2-define-typography-config(
  $font-family: "'Titillium Web', sans-serif",
  $button: mat.m2-define-typography-level(15px, 36px, 600),
);

// Include typography styles
@include mat.all-component-typographies($eu-typography);

// Adjust input height..
// but not from textareas
input.mat-mdc-input-element {
  height: 1rem;
}
