/**
 * NGX-POPPER
 * -----------------------------------------------------------------------------
 * This is to override the ootb look and feel of the popups provided by the
 * ngx-popper library that internally makes use of popper.js
 */
popper-content {
  // VARS
  --_eu-popper-MaxWidth: var(--eu-flyout-MaxWidth, calc(100% - 1rem));
  --_eu-popper-MaxHeight: var(
    --eu-flyout-MaxHeight,
    calc(100% - (1rem + var(--eu-toolbar-Height)))
  );

  --_eu-popper-BackgroundColor: var(--eu-flyout-BackgroundColor, #fff);
  --_eu-popper-BorderRadius: var(--eu-flyout-BorderRadius, 8px);
  --_eu-popper-BoxShadow: var(
    --eu-flyout-BoxShadow,
    0 0 20px 0 rgba(0, 0, 0, 0.25)
  );
  --_eu-popper-Padding: var(--eu-flyout-Padding, 1rem 0.5rem 0.5rem);
  --_eu-popper__arrow-Color: var(--eu-flyout__arrow-Color, #fff);
  --_eu-popper__arrow-Width: var(--eu-flyout__arrow-Width, 16px);
  --_eu-popper__arrow-Height: var(--eu-flyout__arrow-Height, 11px);

  // CALCULATED VARS
  --_eu-popper__arrow-Width--Half: calc(var(--_eu-popper__arrow-Width) * 0.5);
  --_eu-popper__arrow-Height--Half: calc(var(--_eu-popper__arrow-Height) * 0.5);
  --_eu-popper-MarginForArrow: var(--_eu-popper__arrow-Height--Half);
  --_eu-popper__arrow-MarginForArrowCounterpart: calc(
    var(--_eu-popper-MarginForArrow) * -1
  );

  .ngxp__container {
    max-width: var(--_eu-popper-MaxWidth);
    max-height: var(--_eu-popper-MaxHeight);
    background-color: var(--_eu-popper-BackgroundColor);
    border: 0;
    border-radius: var(--_eu-popper-BorderRadius);
    box-shadow: var(--_eu-popper-BoxShadow);
    padding: var(--_eu-popper-Padding);
    z-index: $z-popper__container;

    &[aria-hidden='false'] {
      display: flex !important;
    }

    // aside
    &[x-placement^='right'],
    &[x-placement^='left'] {
      > .ngxp__arrow {
        border-top-width: calc(var(--_eu-popper__arrow-Width) * 0.5);
        border-bottom-width: calc(var(--_eu-popper__arrow-Width) * 0.5);
      }
    }

    // above/below
    &[x-placement^='top'],
    &[x-placement^='bottom'] {
      > .ngxp__arrow {
        border-right-width: calc(var(--_eu-popper__arrow-Width) * 0.5);
        border-left-width: calc(var(--_eu-popper__arrow-Width) * 0.5);
      }
    }

    // top
    &[x-placement^='top'] {
      margin-bottom: var(--_eu-popper-MarginForArrow);

      > .ngxp__arrow {
        border-top-width: var(--_eu-popper__arrow-Height--Half);
        bottom: var(--_eu-popper__arrow-MarginForArrowCounterpart);
      }
    }

    // right
    &[x-placement^='right'] {
      margin-left: var(--_eu-popper-MarginForArrow);

      > .ngxp__arrow {
        border-right-width: var(--_eu-popper__arrow-Height--Half);
        left: var(--_eu-popper__arrow-MarginForArrowCounterpart);
      }
    }

    // bottom
    &[x-placement^='bottom'] {
      margin-top: var(--_eu-popper-MarginForArrow);

      > .ngxp__arrow {
        border-bottom-width: var(--_eu-popper__arrow-Height--Half);
        top: var(--_eu-popper__arrow-MarginForArrowCounterpart);
      }
    }

    // left
    &[x-placement^='left'] {
      margin-right: var(--_eu-popper-MarginForArrow);

      > .ngxp__arrow {
        border-left-width: var(--_eu-popper__arrow-Height--Half);
        right: var(--_eu-popper__arrow-MarginForArrowCounterpart);
      }
    }

    // inner
    > .ngxp__inner {
      display: flex;
      overflow: auto;
    }

    // arrow
    > .ngxp__arrow {
      display: var(--_eu-popper__arrow-Display, block);
      border-color: var(--_eu-popper__arrow-Color);
    }
  }

  // as modal
  &.is-modal {
    --_eu-popper__arrow-Display: none;
    --_eu-popper-MarginForArrow: 0px;

    .ngxp__container {
      margin-top: calc(var(--eu-toolbar-Height) / 2); // half toolbar height
      position: fixed !important;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
      width: max-content;
      height: max-content;
    }
  }
}

@include media('>=tabletPortrait') {
  popper-content {
    --_eu-popper-MaxWidth: var(--eu-flyout-MaxWidth, calc(100% - 2rem));
    --_eu-popper-MaxHeight: var(
      --eu-flyout-MaxHeight,
      calc(100% - (2rem + var(--eu-toolbar-Height)))
    );

    --_eu-popper-Padding: var(--eu-flyout-Padding, 2rem 1.5rem 1.5rem);
  }
}
