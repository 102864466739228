/**
 * HEADINGS
 */

h1,
h2,
h3,
h4 {
  line-height: 1.25;
}

h1,
.eu-h1 {
  font-size: var(--eu-global-FontSize--4xl);
  font-weight: var(--eu-global-FontWeight--light);

  &:not(:first-child) {
    margin-top: 0.5rem;
  }

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
}

h2,
.eu-h2 {
  font-size: var(--eu-global-FontSize--3xl);
  font-weight: var(--eu-global-FontWeight--light);

  &:not(:first-child) {
    margin-top: 1rem;
  }

  &:not(:last-child) {
    margin-bottom: 0.75rem;
  }
}

h3,
.eu-h3 {
  font-size: var(--eu-global-FontSize--2xl);
  font-weight: var(--eu-global-FontWeight--light);

  &:not(:first-child) {
    margin-top: 0.75rem;
  }

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
}

h4,
.eu-h4 {
  font-size: var(--eu-global-FontSize);
  font-weight: var(--eu-global-FontWeight--semi-bold);
}

p,
.eu-p {
  font-size: var(--eu-global-FontSize--l);

  &:not(:first-child) {
    margin-top: 1rem;
  }

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.eu-app--webshop {
  h1,
  h2,
  h3 {
    font-weight: var(--eu-global-FontWeight--bold, #{$global-FontWeight--bold});
  }
}

// .eu-h1--ui,
// .eu-h2--ui,
// .eu-h3--ui {
//   display: block;
//   font-weight: var(--eu-global-FontWeight--bold);
// }

// .eu-h1--ui {
//   font-size: var(--eu-global-FontSize--3xl);
// }

// .eu-h2--ui {
//   font-size: var(--eu-global-FontSize--2xl);
// }

// .eu-h3--ui {
//   font-size: var(--eu-global-FontSize--m);
// }

// .eu-text--s {
//   font-size: var(--eu-global-FontSize--s);
// }
