/**
 * CONTEN SECTION CLASS(ES)
 */

.eu-content-section {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.eu-content-section__head {
  --_eu-content-section__head-Padding: var(
    --eu-content-section__head-Padding,
    var(--EU-SPACE) var(--EU-SPACE--XS)
  );

  flex: none;
  padding: var(--_eu-content-section__head-Padding);
}

.eu-content-section__main {
  --_eu-content-section__main-Padding: var(
    --eu-content-section__main-Padding,
    0 var(--EU-SPACE--XS)
  );

  flex: 1;
  overflow: auto;
  padding: var(--_eu-content-section__main-Padding);
}

.eu-content-section__footer {
  --_eu-content-section__footer-Padding: var(
    --eu-content-section__footer-Padding,
    var(--EU-SPACE) var(--EU-SPACE--XS)
  );

  flex: none;
  padding: var(--_eu-content-section__footer-Padding);
}

@include media('>=tabletPortrait') {
  .eu-content-section__head {
    --_eu-content-section__head-Padding: var(
      --eu-content-section__head-Padding,
      var(--EU-SPACE)
    );
  }

  .eu-content-section__main {
    --_eu-content-section__main-Padding: var(
      --eu-content-section__main-Padding,
      0 var(--EU-SPACE)
    );
  }

  .eu-content-section__footer {
    --_eu-content-section__footer-Padding: var(
      --eu-content-section__footer-Padding,
      var(--EU-SPACE)
    );
  }
}
