/**
 * Remove the unit of a length
 * @param {Number} $number - Number to remove unit from
 * @return {Number} - Unitless number
 * @example scss - Usage
 * strip-unit(10px) -> 10
 * strip-unit(2em) -> 2
 * strip-unit(3) -> 3
 */
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    // @return calc($number / ($number * 0 + 1));
    @return calc($number / 1);
  }
  @return $number;
}

/**
 * Convert value to em.
 */
@function em($size, $base-font-size: 16px) {
  @return calc(strip-unit($size) / strip-unit($base-font-size) * 1em);
}

/**
 * Convert value to rem.
 */
@function rem($size, $base-font-size: 16px) {
  @return calc(strip-unit($size) / strip-unit($base-font-size) * 1rem);
}
