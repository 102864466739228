/**
 * CSS RESET
 * ----------------------------------------------------------------------------
 * A lot of the resets done here are inspired by or taken from one of the
 * following sources:
 * http://meyerweb.com/eric/tools/css/reset/
 * https://github.com/murtaugh/HTML5-Reset
 * https://github.com/shannonmoeller/reset-css
 * https://github.com/twbs/bootstrap/blob/v4-dev/scss/_reboot.scss
 */

/**
 * BOX-SIZING
 * ----------------------------------------------------------------------------
 * Apply a natural box layout model to all elements, but keep allowing
 * components to change the property.
 * Inspired by
 * http://www.paulirish.com/2012/box-sizing-border-box-ftw/
 * https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-
 * practice/
 */

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

/**
 * TAP HIGHLIGHT
 * ----------------------------------------------------------------------------
 * Remove tap highlight (on iOS etc.)
 */

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/**
 * FOCUS OUTLINE
 * ----------------------------------------------------------------------------
 * Suppress the focus outline on elements that cannot be accessed via keyboard.
 * This prevents an unwanted focus outline from appearing around elements that
 * might still respond to pointer events.
 * Credit: https://github.com/suitcss/base
 */

[tabindex='-1']:focus {
  outline: none !important;
}

/**
 * TYPOGRAPHY
 * ----------------------------------------------------------------------------
 * (T1): Remove top margins from headings
 * By default, '<h1>'-'<h6>' all receive top and bottom margins. We nuke the
 * top margin for easier control within type scales as it avoids margin
 * collapsing.
 * (T2): Reset margins on paragraphs
 * Similarly, the top margin on '<p>'s get reset. However, we also reset the
 * bottom margin to use 'rem' units instead of 'em'.
 */

/* (T1) */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0;
}

/* (T2) */
p {
  margin-top: 0;
  margin-bottom: 0;
}

/**
* MISC
* ----------------------------------------------------------------------------
*/
ol,
ul {
  &[class] {
    list-style: none;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
}

figure {
  margin: 0;
  padding: 0;
}

img {
  vertical-align: bottom;
  max-width: 100%;
  height: auto;
}

address {
  font-style: normal;
}

input::-moz-focus-inner {
  border: 0;
}

a:-moz-focusring,
button:-moz-focusring {
  outline: none;
}
