/**
 * CONTACT GROUP CLASS(ES)
 */

.eu-page-img {
  --_eu-page-img: var(
    --eu-page-img,
    url('/assets/img/page_top_image/kontakt/top_image_Kontakt.jpg')
  );
  --_eu-page-img-pos: var(--eu-page-img-pos, center center);

  height: 12rem;
  width: calc(100% + 1rem);
  background-image: var(--_eu-page-img);
  background-position: var(--_eu-page-img-pos);
  background-repeat: no-repeat;
  background-size: cover;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

@include media('>=tabletPortrait') {
  .eu-page-img {
    height: 16rem;
    border-radius: 1rem;
    margin-bottom: 1rem;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
}

@include media('>=desktop') {
  .eu-page-img {
    height: 20rem;
  }
}
