/**
 * Ideally, this file can be seen as a blueprint for a theme, providing all the
 * relevant colors, fonts, sizes and component-specific vars that are needed to
 * apply a custom look & feel to the UI.
 */

/**
 * IMPORTS
 */
@import 'node_modules/include-media/dist/include-media';
@import 'settings.colors';
@import 'settings.fonts';
@import 'settings.shadows';
@import 'settings.sizes';
@import 'settings.z-index';

/**
 * VARIABLES
 */
:root {
  // GLOBAL: Logo
  // ---------------------------------------------------------------------------
  --eu-global-Logo: './assets/img/eurofleurs-logo.png';

  // GLOBAL: Font
  // ---------------------------------------------------------------------------
  --eu-global-FontFamily: #{$global-FontFamily};
  --eu-global-FontWeight--light: #{$global-FontWeight--light};
  --eu-global-FontWeight--regular: #{$global-FontWeight--regular};
  --eu-global-FontWeight--semi-bold: #{$global-FontWeight--semi-bold};
  --eu-global-FontWeight--bold: #{$global-FontWeight--bold};
  --eu-global-FontWeight: #{$global-FontWeight};

  --eu-global-FontSize--xs: #{$global-FontSize--xs};
  --eu-global-FontSize--s: #{$global-FontSize--s};
  --eu-global-FontSize: #{$global-FontSize};
  --eu-global-FontSize--l: #{$global-FontSize--l};
  --eu-global-FontSize--xl: #{$global-FontSize--xl};
  --eu-global-FontSize--2xl: #{$global-FontSize--2xl};
  --eu-global-FontSize--3xl: #{$global-FontSize--3xl};
  --eu-global-FontSize--4xl: #{$global-FontSize--4xl};

  --eu-global-fluid-Fontsize--min: #{$global-fluid-FontSize--min};
  --eu-global-fluid-Fontsize--max: #{$global-fluid-FontSize--max};
  --eu-global-fluid-screen--min: #{$global-fluid-screen--min};
  --eu-global-fluid-screen--max: #{$global-fluid-screen--max};

  // GLOBAL: Colors
  // ---------------------------------------------------------------------------
  --eu-global-Color--Primary: #{$global-Color--Primary};
  --eu-global-Color--TextOnPrimary: #{$global-Color--TextOnPrimary};
  --eu-global-Color--Focus: #{$global-Color--Focus};

  --eu-global-Color-Base: #{$global-Color-Base};
  --eu-global-Color-Base--70: #{$global-Color-Base--70};
  --eu-global-Color-Base--50: #{$global-Color-Base--50};
  --eu-global-Color-Base--30: #{$global-Color-Base--30};
  --eu-global-Color-Base--10: #{$global-Color-Base--10};
  --eu-global-Color-Base--05: #{$global-Color-Base--05};
  --eu-global-Color-Base--03: #{$global-Color-Base--03};
  --eu-global-Color-TextOnBase: #{$global-Color-TextOnBase};

  --eu-global-Color-Leading--110: #{$global-Color-Leading--110};
  --eu-global-Color-Leading--105: #{$global-Color-Leading--105};
  --eu-global-Color-Leading: #{$global-Color-Leading};
  --eu-global-Color-Leading--25: #{$global-Color-Leading--25};
  --eu-global-Color-Leading--10: #{$global-Color-Leading--10};
  --eu-global-Color-TextOnLeading: #{$global-Color-TextOnLeading};

  --eu-global-Color-Accent: #{$global-Color-Accent};
  --eu-global-Color-Accent--10: #{$global-Color-Accent--10};
  --eu-global-Color-Accent--05: #{$global-Color-Accent--05};

  --eu-global-Color-TextOnAccent: #{$global-Color-TextOnAccent};

  --eu-global-Color-State--error: #{$global-Color-State--error};
  --eu-global-Color-State--warning: #{$global-Color-State--warning};
  --eu-global-Color-State--success: #{$global-Color-State--success};

  // GLOBAL: Text color
  // ---------------------------------------------------------------------------
  --eu-global-LinkColor: var(--eu-global-Color--Primary);
  --eu-global-TextColor: var(--eu-global-Color--Primary);

  // GLOBAL: Spacing
  // ---------------------------------------------------------------------------
  --EU-SPACE--XXS: #{$eu-space--xxs}; //  0.25rem : var(--EU-SPACE-XXS, #{$eu-space--xxs})
  --EU-SPACE--XS: #{$eu-space--xs}; //  0.5rem  : var(--EU-SPACE-XS, #{$eu-space--xs})
  --EU-SPACE--S: #{$eu-space--s}; //  0.75rem : var(--EU-SPACE-S, #{$eu-space--s})
  --EU-SPACE: #{$eu-space}; //  1rem    : var(--EU-SPACE, #{$eu-space})
  --EU-SPACE--L: #{$eu-space--l}; //  1.25rem
  --EU-SPACE--XL: #{$eu-space--xl}; //  1.5rem
  --EU-SPACE--XXL: #{$eu-space--xxl}; //  2rem

  // COMPONENT: App
  // ---------------------------------------------------------------------------
  // --eu-app__content-Background: url('/assets/img/dashboard-bg.jpg');
  // --eu-app__container-Padding: #{$spacing-m--em};

  // COMPONENT: Toolbar (Header)
  // ---------------------------------------------------------------------------
  --eu-toolbar-Height: 50px;
  --eu-toolbar-BackgroundColor: var(--eu-global-Color--Primary);
  --eu-toolbar-Color: var(--eu-global-Color--TextOnPrimary);
  --eu-toolbar__logo-Width: 147px; // 3/4 of large version

  // PRODUCT LIST
  // ---------------------------------------------------------------------------
  // --eu-product-list__tile-MinWidth: 300px;
  // --eu-product-list__tile-MaxWidth: 1fr;
  // --eu-product-list-GridGap: 1rem;

  // VIEW CONTAINER
  // ---
  // --eu-view-container__content-BorderRadius: 1.5rem;
  // --eu-view-container__content-BoxShadow: #{$eu-shadow--2};
}

.eu-app--webshop {
  --eu-global-FontSize--xl: #{$global-FontSize};
  --eu-global-FontSize--2xl: #{$global-FontSize--l};
  --eu-global-FontSize--3xl: #{$global-FontSize--xl};
  --eu-global-FontSize--4xl: #{$global-FontSize--2xl};
}

@include media('>=tabletPortrait') {
  :root {
    --eu-toolbar-Height: 60px;
    --eu-toolbar__logo-Width: 196px;
  }

  .eu-app--webshop {
    --eu-global-FontSize--xl: #{$global-FontSize--l};
    --eu-global-FontSize--2xl: #{$global-FontSize--xl};
    --eu-global-FontSize--3xl: #{$global-FontSize--2xl};
    --eu-global-FontSize--4xl: #{$global-FontSize--3xl};
  }
}

@include media('>=desktopLarge') {
  :root {
    --eu-toolbar-Height: 80px;
    // --eu-app__container-Padding: #{$spacing-l--em};
  }
}
