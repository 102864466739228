/**
 * CONTAINER CLASS(ES)
 */

.eu-container {
  --_eu-app__container-Padding: var(
    --eu-app__container-Padding,
    var(--EU-SPACE--XS, #{$eu-space--xs})
  );
  --_eu-app__container-Max-Width: var(
    --eu-app__container-MaxWidth,
    #{$container-width--max}
  );

  padding: var(--_eu-app__container-Padding);
}

.eu-container--boxed {
  max-width: var(--_eu-app__container-Max-Width);
  margin-left: auto;
  margin-right: auto;
}

@include media('>=tabletPortrait') {
  .eu-container {
    --_eu-app__container-Padding: var(
      --eu-app__container-Padding,
      var(--EU-SPACE, #{$eu-space})
    );
  }
}

@include media('>=desktop') {
  .eu-container {
    --_eu-app__container-Padding: var(
      --eu-app__container-Padding,
      var(--EU-SPACE--XL, #{$eu-space--xl})
    );
  }
}
