/**
 * This file can be considered the major styling file of the project.
 * Its purpose is to aggregate other styling resources (SCSS) in the manner of
 * ITCSS (see: http://bit.ly/1OHRsEw).
 * One of the key principles of ITCSS is that it separates your CSS codebase to
 * several sections (called layers), which take form of the inverted triangle.
 * ----------------------------------------------------------------------------
 * SETTINGS
 * Used with preprocessors and contain font, color definitions, etc.
 * ----------------------------------------------------------------------------
 * TOOLS
 * Globally used mixins and functions. It is important not to output any CSS in
 * the first 2 layers.
 * ----------------------------------------------------------------------------
 * GENERIC
 * Reset and/or normalize styles, box-sizing definition, etc. This is the first
 * layer which generates actual CSS.
 * ----------------------------------------------------------------------------
 * ELEMENTS
 * Styling for bare HTML elements (like H1, A, etc.). These come with default
 * styling from the browser so we can redefine them here.
 * ----------------------------------------------------------------------------
 * OBJECTS
 * Class-based selectors which define undecorated design patterns, for example
 * media object known from OOCSS.
 * ----------------------------------------------------------------------------
 * COMPONENTS
 * Specific UI components. This is where the majority of our work takes place
 * and our UI components may often be composed of Objects and other components.
 * NOTE: Basically there will be a custom SCSS file going together with every
 * component, so most probably no _components.scss file needed.
 * ----------------------------------------------------------------------------
 * TRUMPS
 * Utilities and helper classes with ability to override anything which goes
 * before in the triangle, eg. hide helper class.
 */

/* 3rd party libraries etc. */
@import '../../../node_modules/flag-icons/css/flag-icons.min.css';
@import '../../../node_modules/include-media/dist/include-media';

@import 'styles/settings';
@import 'styles/tools';
@import 'styles/generic';
@import 'styles/elements';
@import 'styles/trumps';

/**
 * TODO: move elsewhere
 */
// temp. ng-toasts fix
ng-toasts {
  bottom: auto;
  --ng-toasts-Padding: 0;
  top: 6rem;
  right: 1rem;
}

// Cookie Consent

.cc-message {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
