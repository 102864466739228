@import './tools.functions';

/**
 * FLUID FONT SIZE
 * Based on example here https://css-tricks.com/snippets/css/fluid-typography/
 */

@mixin fluid-type($font-min, $font-max, $screen-min, $screen-max) {
  font-size: calc(#{$font-min} * 1px);

  @media only screen and (min-width: #{$screen-min}px) {
    font-size: calc(
      calc(#{$font-min} * 1px) + (#{$font-max} - #{$font-min}) *
        (100vw - #{$screen-min}px) / (#{$screen-max} - #{$screen-min})
    );
  }

  @media only screen and (min-width: #{$screen-max}px) {
    font-size: calc(#{$font-max} * 1px);
  }
}

/**
 * MATERIAL ICON STYLE
 * This is usefull if you want to use material icons inside css, e.g. as
 * a ::after element.
 */

@mixin material-icons() {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}
