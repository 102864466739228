@import './settings.colors';

/**
 * BUTTON CLASS(ES)
 */
.eu-button {
  --og-button-Background: #{$global-Color-Leading};
  --og-button-Background--hover: #{$global-Color-Leading--105};
  --og-button-Background--active: #{$global-Color-Leading--110};
  --og-button-Color: #{$global-Color-TextOnLeading};
  --og-button-Color--hover: #{$global-Color-TextOnLeading};
  --og-button-Color--active: #{$global-Color-TextOnLeading};
  --og-button-BorderRadius: 2rem;
  --og-button-WhiteSpace: normal;
  --Padding: 0.5rem 1.25rem;
  --TextTransform: normal;
  --og-button-FontWeight: 600;

  border-radius: var(--og-button-BorderRadius);
  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.25);
}

.eu-button--second {
  --og-button-Background: #fff;
  --og-button-Background--hover: #{$global-Color-Accent--05};
  --og-button-Background--active: #{$global-Color-Accent--10};
  --og-button-Color: #{$global-Color-TextOnAccent};
  --og-button-Color--hover: #{$global-Color-TextOnAccent};
  --og-button-Color--active: #{$global-Color-TextOnAccent};
}

// Prepending .eu-app to the original class assures that we override it.
.eu-app {
  --mat-button-BorderRadius: 2rem;

  .mat-mdc-button,
  .mat-mdc-raised-button {
    border-radius: var(--mat-button-BorderRadius);
    mat-icon {
      margin-right: 0.25rem;
    }
  }
  .mat-button-toggle,
  .mat-button-toggle-group,
  .mat-button-toggle-ripple {
    border-radius: var(--mat-button-BorderRadius);
  }
  .mat-button-toggle-group {
    background: #fff;
    padding: 0.25rem;
  }
  .mat-button-toggle {
    width: 35px;
    height: 35px;

    mat-icon {
      margin-bottom: 0.5rem;
    }
    .mat-button-toggle-label-content {
      line-height: 40px;
      padding: 0 6px;
    }
  }
  .mat-button-toggle + .mat-button-toggle {
    margin-left: 0.5rem;
  }
  .mat-button-toggle-checked {
    background-color: #07313b;
    mat-icon {
      color: white;
    }
  }

  .mat-mdc-button.mat-mdc-button-base, .mat-mdc-raised-button.mat-mdc-button-base, .mat-mdc-unelevated-button.mat-mdc-button-base, .mat-mdc-outlined-button.mat-mdc-button-base {
    height: 40px;
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 5px;
    margin-bottom: 5px;;
  }
}
@media only screen and (max-width: 500px) {
  // .eu-product-explorer__rows.ng-star-inserted {
  //   .eu-product-row__actions{
  //     flex-direction: row!important;
  //     flex-wrap: wrap!important;
  //   }
  //   #quickAddButton{
  //     .mdc-button__label{display: none;}
  //     &::after{
  //       content: "+";
  //       line-height: 40px;
  //       color: #fff;
  //       display: inline-block;
  //     }
  //   }
  // }
}
