/**
 * UTILITY CLASSES
 * ----------------------------------------------------------------------------
 * Misc utility classes, e.g. for layout purposes.
 */
.d-block {
  display: block;
}

.d-inline-block {
  display: inline-block;
}

.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
  align-items: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink-1 {
  flex-shrink: 1;
}

.flex-initial {
  // This is the same as flex: initial; and the shorthand for the
  // default value: flex: 0 1 auto. It sizes the item based on its
  // width/height properties (or its content if not set).
  flex: 0 auto;
}

.flex-auto {
  // This is equivalent to flex: 1 1 auto. It sizes the item based on
  // its width/height properties, but makes it fully flexible so that
  // they absorb any extra space along the main axis.
  flex: auto;
}

.flex-none {
  // This is equivalent to flex: 0 0 auto. It sizes the item according
  // to its width/height properties, but makes it fully inflexible.
  flex: none;
}

.flex-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.flex-1-1-0 {
  flex: 1 1 0;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-center {
  align-items: center;
}

.align-self-start {
  align-self: flex-start;
}

.align-self-end {
  align-self: flex-end;
}

.align-self-center {
  align-self: center;
}

.align-self-stretch {
  align-self: stretch;
}

.align-content-start {
  align-content: flex-start;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.w-100 {
  width: 100%;
}

.max-w-100 {
  max-width: 100%;
}

.min-w-100 {
  min-width: 100%;
}

.h-100 {
  height: 100%;
}

.max-h-100 {
  max-height: 100%;
}

.min-h-100 {
  min-height: 100%;
}

.lh-normal {
  line-height: normal;
}

.m-0 {
  margin: 0 !important;
}

.m-horizontal-auto {
  margin-left: auto;
  margin-right: auto;
}

.m-vertical-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.m-left-auto {
  margin-left: auto;
}

.m-right-auto {
  margin-right: auto;
}

.p-0 {
  padding: 0 !important;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.c-pointer {
  cursor: pointer;
}

.c-not-allowed {
  cursor: not-allowed;
}

.no-pe {
  pointer-events: none;
}

.no-select {
  user-select: none;
}

.no-bg {
  background: none !important;
  background-color: transparent !important;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-nowrap {
  white-space: nowrap;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-light {
  font-weight: $global-FontWeight--light;
}

.text-semi-bold {
  font-weight: $global-FontWeight--semi-bold;
}

.text-bold {
  font-weight: $global-FontWeight--bold;
}

.break-all {
  word-break: break-all;
}

.break-word {
  word-break: break-word;
}

.t-o-center {
  transform-origin: center;
}

.rotate-180 {
  transform: rotate(180deg);
}
